/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <ConfirmDialog></ConfirmDialog>
    <PSidebar
      class="p-sidebar-lg p-sidebar-close"
      :dismissable="true"
      :visible.sync="displayDialog"
      position="right"
      containerStyle="{width: '70vw'}"
    >
      <offre-detail-viewer :offre="activeOffre" />
    </PSidebar>
    <OffreExterneShareEditor
      ref="socialShareEditor"
      title="Editeur du contenu des partages sociaux"
    />
    <PDialog
      :header="'Information sur la date publication'"
      :modal="true"
      :visible.sync="displayValidationDialog"
      :contentStyle="{ overflow: 'visible' }"
      :containerStyle="{ width: '30vw' }"
    >
      <form class="row g-3">
        <!-- <div class="row">
          <div class="col-sm-12">
            <label>Observation</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <div class="col-sm-12 mb-sm-12">
                  <vue-editor
                    id="observation"
                    v-model="activeOffre.observation"
                  ></vue-editor>
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <label>Justification</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <div class="col-sm-12 mb-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    id="justification"
                    placeholder="Justification"
                  />
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div> -->
        <div class="row mt-3">
          <div class="col-sm-6">
            <label>Date debut candidature</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateEff"
                  :manualInput="false"
                  v-model="activeOffre.dateEff"
                  :showWeek="true"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <label>Date de clôture candidature</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateEch"
                  :manualInput="false"
                  v-model="activeOffre.dateEch"
                  :showWeek="true"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label>Date debut publication</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateDebutPub"
                  :manualInput="false"
                  v-model="activeOffre.dateDebutPub"
                  :showWeek="true"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <label>Date fin publication</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <p-calendar
                  class="col-12 md:col-12"
                  :showIcon="true"
                  size="sm"
                  locale="fr"
                  name="dateFinPub"
                  :manualInput="false"
                  v-model="activeOffre.dateFinPub"
                  :showWeek="true"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
      <template #footer>
        <PButton
          label="Fermer"
          icon="pi pi-times"
          @click="displayValidationDialog = false"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="validerOffre()" autofocus />
      </template>
    </PDialog>

    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.4em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>

        <transition>
          <div class="border-0 shadow-0 mt-2">
            <div v-if="showAdvancedFilter" class="row p-fluid grid">
              <div class="col-12 col-sm-6 col-md-3 mb-4">
                <p-calendar
                  placeholder="Date de début"
                  v-model="dateDeb"
                  locale="fr"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3 mb-4">
                <!-- <label for="basic">Date de fin</label> -->
                <p-calendar
                  placeholder="Date de fin"
                  class="flex-grow-1"
                  v-model="dateFin"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <template v-for="(option, index) in filterOptions">
                <div
                  class="col-12 col-sm-6 col-md-3 mb-4"
                  v-if="option.visible"
                  :key="index"
                >
                  <!-- <p-multi-select v-model="option.value" :options="getItemsOf(option.getterName)" :optionLabel="option.labelField" :selectionLimit="3" :placeholder="option.label" optionValue="id" :filter="true" display="chip"/> -->
                  <AsyncMultiSelect
                    v-model="option.value"
                    :multiple="true"
                    :queryUrl="option.queryUrl"
                    :optionLabel="option.labelField"
                    :placeholder="option.label"
                    :displayMode="option.displayMode"
                    :emitObject="false"
                    :searchOptions="[option.labelField]"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des offres externes externe</h4>
        </div>
        <div class="btn-group">
          <a
            @click.prevent="
              $router.push({
                name: 'espace.common.offre.externe.add',
                params: { userRole: $userRoleName },
              })
            "
            href=""
            class="style_btn btn btn-warning"
            >Ajouter une offre</a
          >
          <a
            @click.prevent="publierToutesMesOffres"
            href=""
            class="style_btn btn btn-outline-warning ms-4 text-dark"
            >Publier toutes mes offres</a
          >
        </div>

        <div class="btn-group ms-4">
          <a
            @click.prevent="$refs.socialShareEditor.show()"
            href=""
            class="style_btn btn btn-outline-info"
            >Partager <i class="bi bi-share"></i
          ></a>
        </div>
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>
                <template #cell(nom)="{ item: offre }">
                  <span class="text-uppercase">{{ offre.nom }}</span>
                </template>
                <template #cell(prenom)="{ item: offre }">
                  <span class="text-uppercase">{{ offre.prenom }}</span>
                </template>
                <template #cell(creePar)="{ item: offre }">
                  <span class="text-uppercase" v-if="offre.creePar"
                    >{{ offre.creePar.nom }} {{ offre.creePar.prenom }}</span
                  >
                </template>
                <template #cell(validePar)="{ item: offre }">
                  <span class="text-uppercase" v-if="offre.validePar"
                    >{{ offre.validePar.nom }} {{ offre.validePar.prenom }}</span
                  >
                </template>
                <template #cell(dateEff)="{ item: offre }">
                  <span class="text-uppercase" v-if="offre.dateEff"
                    >{{ offre.dateEff | moment("DD-MM-YYYY") }}
                  </span>
                </template>

                <template #cell(dateEch)="{ item: offre }">
                  <span class="text-uppercase" v-if="offre.dateEch"
                    >{{ offre.dateEch | moment("DD-MM-YYYY") }}
                  </span>
                </template>
                <template #cell(status)="{ item: offre }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusOffreColor(offre.status)"
                    v-if="offre.status"
                    >{{ offre.status }}
                  </PTag>
                </template>
                <template #cell(fullPhotoUrl)="{ item: offre }">
                  <div v-if="offre.typeFichier == 'pdf'">
                    <a
                      class="btn btn-sm py-1 btn-outline-secondary me-1"
                      :href="offre.fullPhotoUrl"
                      download
                      target="_blank"
                    >
                      <i class="bi bi-eye"></i>
                    </a>
                  </div>
                  <div v-else>
                    <PImagePreview
                      v-if="offre.fullPhotoUrl"
                      :src="offre.fullPhotoUrl"
                      alt="Image "
                      width="30"
                      preview
                    />
                  </div>
                </template>

                <!-- <template #row-details="row">
                  <CandidatureList
                    :offreId="row.item.id"
                    :extraQueryArg="`offreId=${row.item.id}`"
                  />
                </template> -->
                <template #cell(dateCloture)="{ item: offre }">
                  <div>
                    <span
                      v-tooltip.top="$moment(offre.dateCloture).format('DD-MM-YYYY')"
                      class="text"
                      v-if="offre.dateCloture"
                      >{{ $moment(offre.dateCloture).fromNow() }}
                    </span>
                    <!-- <span class="text">{{
                      $moment(offre.dateCloture).format("DD-MM-YYYY")
                    }}</span> -->
                  </div>
                </template>
                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <a
                      v-tooltip.top="'Consulter et modifier'"
                      class="btn btn-success rounded-0 btn-sm me-1"
                    >
                      <i class="bi bi-pencil-square" @click.stop="update(row.item)"></i
                    ></a>
                    <a
                      class="btn btn-info rounded-0 btn-sm me-1"
                      title="Validation de l'offre"
                      v-tooltip.top="'Validation d\'une offre'"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      @click.prevent="showValiderOffreDialog(row.item)"
                      @mouseenter="setDatePub(row.item)"
                      ><i class="bi bi-clipboard-check"></i
                    ></a>
                    <a
                      class="btn btn-warning rounded-0 btn-sm me-1"
                      title="Publier une offre"
                      v-if="$can('publish', 'Offre')"
                      @click.stop="publierOffre(row.item)"
                      ><i class="bi bi-box-arrow-up-right"></i
                    ></a>
                    <a
                      class="btn btn-danger rounded-0 btn-sm me-1"
                      title="Annuler une offre"
                      @click.stop="annluerOffre(row.item)"
                      ><i class="bi bi-x-octagon"></i
                    ></a>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <!-- <span class="text-muted mb-3">Votre porte-feuille est vide</span> -->
          <!-- <a v-if="$can('create', 'Azoli')" @click.prevent="$router.push({ name: 'espace.entreprise.offre.add' })" href="" class="style_btn btn btn-sm btn-outline-primary">Ajouter un PPI</a> -->
          <a
            @click.prevent="$router.push({ name: 'espace.entreprise.offre.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter une offre</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "@/mixins/auth-mixin";
import CAutoComplete from "../../../../components/common/CAutoComplete.vue";
import AsyncMultiSelect from "../../../../components/common/AsyncMultiSelect.vue";
import OffreDetailViewer from "../../../portail/OffreDetailViewer.vue";
import OffreExterneShareEditor from "../../../../components/espace/common/offre-externe/OffreExterneShareEditor.vue";

export default {
  propos: {
    extraQueryArg: {
      type: String,
      default: ``,
    },
  },
  components: {
    OffreDetailViewer,
    CAutoComplete,
    AsyncMultiSelect,
    OffreExterneShareEditor,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      modal1: "",
      dateDeb: null,
      dateFin: null,
      displayValidationDialog: false,
      showAdvancedFilter: false,
      displayDialog: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      activeOffre: {},
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des offres externes",
                subtitle: "Liste des offres externes",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "offresExternes/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des offres externes.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des offres externes",
                subtitle: "Liste des offres externes",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "offresExternes/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des offres externes.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["intitule"],
      searchFieldsOptions: [
        {
          label: "Inititulé du poste",
          value: "intitule",
          visible: true,
        },
        {
          label: "Entreprise",
          value: "entreprise",
          visible: true,
        },
        {
          label: "Compétences",
          value: "competence",
          visible: true,
        },
        {
          label: "Nature du contract",
          value: "nature",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "metier",
          value: "",
          displayMode: "classic",
          label: "Inititulé du poste",
          queryUrl: "romes",
          valueKey: "id",
          labelField: "libelle",
          dropdown: true,
          visible: true,

          // items:,
        },
        {
          column: "niveau",
          value: "",
          label: "Niveaux",
          dropdown: true,
          displayMode: "mega-menu",
          labelField: "code",
          valueKey: "id",
          queryUrl: "niveaux",
          visible: true,
          // items:,
        },
        {
          column: "domaine",
          value: "",
          label: "Domaine",
          displayMode: "mega-menu",
          labelField: "nom",
          valueKey: "id",
          queryUrl: "domaines",
          dropdown: true,
          visible: true,
        },
        {
          column: "nature",
          value: "",
          label: "Nature contrat",
          displayMode: "mega-menu",
          labelField: "code",
          valueKey: "id",
          queryUrl: "natures",
          dropdown: true,
          visible: true,
        },
      ],
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    // dateDeb(){
    //   this.queryStatistics()
    // },
    // dateFin(){
    //   this.queryStatistics()
    // }
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      candidatures: "candidature/candidatures",
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "intitule", label: "Intitulé" },
        { key: "entreprise", label: "Entreprise" },
        { key: "ageMin", label: "Age Minimum" },
        { key: "ageMax", label: "Age Maximum" },
        { key: "nombre", label: "Nbre. Post" },
        { key: "nature.code", label: "Nature" },
        { key: "dateCloture", label: "Date clôture" },
        { key: "status", label: "statut" },
        { key: "fullPhotoUrl", label: "Fichier" },
        // { key: 'validePar', label: 'Validé par' },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    sharableTitle() {},
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",
      transfererVersConseiller: "transfert/transfererVersConseiller",
      validationOffre: "offreExterne/validationOffre",
      publierMesOffres: "offreExterne/publierMesOffres",
    }),
    validation() {},
    getStatusOffreColor(status) {
      let color = "EN_ATTENTE";
      if (status == "VALIDE") {
        color = "primary";
      } else if (status == "PUBLIE") {
        color = "success";
      } else if (status == "ANNULE") {
        color = "danger";
      } else if (status == "EN_ATTENTE") {
        color = "secondary";
      }
      return color;
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    setDatePub(oneOffre) {
      this.activeOffre = { ...oneOffre };
    },
    annluerOffre(oneOffre) {
      this.$swal
        .fire({
          title: "Confirmation",
          text: "Confirmez-vous l'annulation de cette offre ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            oneOffre.status = "ANNULE";
            this.validationOffre(oneOffre).then(() => {
              this.$toast.error("Offre annulée ", {
                position: "bottom-right",
                duration: 8000,
              });
            });
          }
        });
    },
    validerOffre() {
      this.$swal
        .fire({
          title: "Confirmation",
          text: "Confirmez-vous la validation de l'offre ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.activeOffre.status = "VALIDE";
            this.validationOffre(this.activeOffre).then(() => {
              this.displayValidationDialog = false;
              this.$toast.success("Offre validée ", {
                position: "bottom-right",
                duration: 8000,
              });
            });
          }
        });
    },
    showValiderOffreDialog(offre) {
      this.activeOffre = {
        ...offre,
        dateDebutPub: offre.dateDebutPub || new Date(),
        dateEff: offre.dateCloture,
        dateEch: offre.dateCloture,
        dateFinPub: offre.dateCloture,
      };
      this.displayValidationDialog = true;
    },
    publierOffre(oneOffre) {
      this.$swal
        .fire({
          title: "Confirmation",
          text: "Confirmez-vous la publication de cette offre ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            oneOffre.status = "PUBLIE";
            this.validationOffre(oneOffre).then(() => {
              this.$toast.success("Offre publiée ", {
                position: "bottom-right",
                duration: 8000,
              });
            });
          }
        });
    },
    publierToutesMesOffres(oneOffre) {
      this.$swal
        .fire({
          title: "Confirmation",
          text: "Confirmez-vous la publication de toutes vos offres?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non",
          confirmButtonText: "Oui",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.publierMesOffres().then(() => {
              this.queryStatistics();
              this.$toast.success("Offres publiées ", {
                position: "bottom-right",
                duration: 8000,
              });
            });
          }
        });
    },
    // transfertDemandeur(agent) {
    //   console.log("Agent: ", agent);
    //   if (this.selected.length == 0) {
    //     this.$toast.error(
    //       "Aucun demandeur sélectionné. Passez en mode tableau puis sélectionnez le(s) demandeur(s) avant de continuer. ",
    //       {
    //         position: "top-right",
    //         duration: 8000,
    //       }
    //     );
    //     return;
    //   }
    //   this.transfererVersConseiller({
    //     agent,
    //     demandeur: this.selected[0],
    //   }).then((data) => {
    //     console.log(data);
    //     this.$toast.success("Opération réussie!", {
    //       position: "top-right",
    //       duration: 8000,
    //     });
    //   });
    // },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "offreExterne",
        search: this.search,
        fetcherMethod: "offreExterne/fetchOffres",
        dataGetter: "offreExterne/offres",
        paginationGetter: "offreExterne/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          return `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}`;
        },
        searchFields: this.searchFields,
      });
    },
    // sformatToDataTable(data, { keyName }) {
    //   let summary = {};
    //   return Object.keys(data).map((k) => {
    //     let { key, M = 0, F = 0, total = 0 } = data[k];
    //     summary.M += M;
    //     summary.F += F;
    //     summary.total += total;
    //     if (data[k]["TOTAL"] == undefined) {
    //       return {
    //         [keyName]: key,
    //         M,
    //         F,
    //         total,
    //       };
    //     } else {
    //       return {
    //         [keyName]: "TOTAL",
    //         ...summary,
    //       };
    //     }
    //   });
    // },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    // downloadFiche(de) {
    //   // FileService.downloadFile(`demandeurs${de.id}/pdf`, `fichier_${de.id}.pdf`).then(()=>{})
    //   // this.downloadAzoli(de).then(()=>{})
    //   this.fetchDossier({
    //     demandeurId: de.id,
    //   }).then(() => {
    //     console.log(this.$refs.html2Pdf);
    //     this.$refs.html2Pdf.generatePdf();
    //   });
    // },
    // editDemandeur(demandeur) {
    //   console.log(demandeur);
    //   this.activeDemandeur = { ...demandeur };
    //   this.$router.push({ name: "espace.conseiller.de.edit" });
    // },
    // showDemandeur(demandeur) {
    //   console.log(demandeur);
    //   this.activeDemandeur = { ...demandeur };
    //   this.$router.push({
    //     name: "espace.conseiller.de.detail",
    //     params: { demandeurId: demandeur.id },
    //   });
    // },
    update(offre) {
      console.log("avant offre");
      console.log(offre);
      //this.activeDemandeur = { ...demandeur }
      this.$router.push({
        name: "espace.common.offre.externe.edit",
        params: { offreId: offre.id, userRole: this.$userRoleName },
      });
    },
    // remplirFiche(demandeur) {
    //   this.$router.push({
    //     name: "espace.conseiller.azoli.edit",
    //     params: { demandeurId: demandeur.id },
    //   });
    // },
    // validerFiche(demandeur) {
    //   if (demandeur.status == "VALIDE") {
    //     this.$toast.info("Fiche déjà validée!", {
    //       position: "top-right",
    //       duration: 8000,
    //     });
    //     return;
    //   }

    //   if (demandeur.status != "TERMINE") {
    //     this.$toast.error("Fiche non terminée", {
    //       position: "top-right",
    //       duration: 8000,
    //     });
    //     return;
    //   }

    //   this.$confirm({
    //     title: "Confirmation",
    //     message: `Vous-vous vraiment valider  la fiche Azoli de ${demandeur.nom} ${demandeur.prenom}?`,
    //     button: {
    //       yes: "Oui",
    //       no: "Non",
    //     },
    //     callback: (confirm) => {
    //       if (confirm) {
    //         this.validerDossier({
    //           ...demandeur,
    //           status: "VALIDE",
    //         }).then((data) => {
    //           console.log(data);
    //           this.$toast.success("Fiche validée avec succès!", {
    //             position: "top-right",
    //             duration: 10000,
    //           });
    //         });
    //       }
    //     },
    //   });
    // },
    // async destroyDemandeur(demandeur) {
    //   console.log(demandeur);
    //   await this.$dialog.confirm({
    //     size: "md",
    //     centered: true,
    //     variant: "success",

    //     text: "Voulez-vous vraiment supprimer cet élément?",
    //     actions: {
    //       false: {
    //         text: "Non",
    //         variant: "outline-success",
    //       },
    //       true: {
    //         text: "Oui",
    //         variant: "success",
    //         handle: async () => {
    //           this.deleteDemandeur(demandeur);
    //         },
    //       },
    //     },
    //   });
    // const ok = await this.$dialog.confirm({ text: 'Do you really want to exit?'})
    // if (ok) {
    // }
    // },
    //   updateOrCreateDemandeur(demandeur) {
    //     console.log(demandeur);
    //     this.createOrUpdateDemandeur(demandeur).then((data) => {
    //       console.log(data);
    //       this.$toast.success("Opération réussie!", {
    //         position: "top-right",
    //         duration: 5000,
    //       });
    //     });
    //   },
  },
};
</script>

<style></style>
