<template>
  <div class="">
    <PDialog
      :header="'Editeur du contenu des partages sociaux'"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '60vw' }"
    >
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <PTabView ref="tabview1">
            <PTabPanel header="Mise en for contenu">
              <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                <form ref="form" method="post" @submit.prevent="handleSubmit()">
                  <div class="row">
                    <div class="col-sm-12 field">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form- mb-3">
                          <h6>Titre</h6>

                          <PTextarea
                            class="form-control"
                            placeholder="Titre de la publicaiton"
                            rows="4"
                            v-model="title"
                          />
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="col-sm-12 field">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="form- mb-3">
                          <h6>Description</h6>
                          <PTextarea
                            v-model="content"
                            class="form-control"
                            editorStyle="height: 320px"
                            rows="20"
                          />
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </PTabPanel>
            <PTabPanel header="Apperçu">
              <PTextarea
                class="form-control"
                placeholder="Titre de la publicaiton"
                rows="20"
                v-model="fullContent"
              />
            </PTabPanel>
          </PTabView>
        </div>
        <div class="col-sm-12 col-md-4 pt-2 bg-light">
          <!-- <h6>Apperçu</h6>
            <div v-html="content" class="mt-2 p-3">
            </div> -->
          <ShareNetwork
            network="facebook"
            :url="sharingUrl"
            :title="title"
            :description="content"
            quote=""
            hashtags=""
          >
            <a class="btn py-1 w-100 btn-outline-primary me-1" :href="'#'">
              Facebook <i class="bi bi-share"></i>
            </a>
          </ShareNetwork>
          <br />
          <ShareNetwork
            network="whatsapp"
            :url="sharingUrl"
            :title="title + '\n' + content"
            :description="content"
            quote=""
            hashtags=""
          >
            <a class="btn py-1 btn-outline-primary me-1 mt-4 w-100" :href="'#'">
              Whatsapp <i class="bi bi-share"></i>
            </a>
          </ShareNetwork>
        </div>
      </div>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />

        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    offres: {
      type: Array,
      default: [],
    },
    value: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      display: false,
      title: `𝐋'𝐀𝐍𝐏𝐄 (Agence Nationale Pour l’Emploi) 𝐯𝐨𝐮𝐬 𝐢𝐧𝐯𝐢𝐭𝐞 à 𝐜𝐨𝐧𝐬𝐮𝐥𝐭𝐞𝐫 les 𝐨𝐟𝐟𝐫𝐞𝐬  𝐝'𝐞𝐦𝐩𝐥𝐨𝐢 du ${this.$moment().format(
        "dddd DD MMMM YYYY"
      )} sur www.anpe.bj, pour 𝐨𝐩𝐭𝐢𝐦𝐢𝐬𝐞r 𝐯𝐨𝐬 𝐜𝐡𝐚𝐧𝐜𝐞𝐬 𝐝’𝐞𝐦𝐛𝐚𝐮𝐜𝐡𝐞.`,
      content: null,
      fullContent: null,
    };
  },
  created() {
    this.fetchOffreExterneSharableMesage().then((data) => {
      this.content = data;
      this.fullContent =
        this.title +
        "\n" +
        this.sharingUrl +
        "\n\n" +
        this.content +
        "\n\n" +
        "_Cliquez sur le lien suivant pour consulter nos offres_\n" +
        this.sharingUrl;
    });
  },
  watch: {},
  computed: {
    sharingUrl() {
      const route = this.$router.resolve({
        name: "portail.offres.list",
        query: { typeOffre: "offreExterne" },
      });
      const absoluteURL = new URL(route.href, window.location.origin).href;
      return absoluteURL;
    },
  },
  methods: {
    ...mapActions({
      fetchOffreExterneSharableMesage: "offreExterne/fetchOffreExterneSharableMesage",
    }),
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    ok() {
      this.$emit("saved", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("saved", this.editableItem);
      this.hide();
    },
  },
};
</script>
